import React from "react"
import styled from "styled-components"

import Layout from "../layout.js"
import NetlifyForm from "../components/NetlifyForm"

import Li from "../assets/linkedin-in-brands.svg"
import Twit from "../assets/twitter-brands.svg"
import { motion, AnimatePresence } from "framer-motion"
// import { useInView } from "react-intersection-observer"

// const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

// const letter = {
//   initial: {
//     y: 15,
//     opacity: 0,
//   },
//   animate: {
//     y: 0,
//     opacity: 1,
//     transition: { duration: 1, ...transition },
//   },
// }

export default function Hire() {
  // const [ref, inView] = useInView({
  //   triggerOnce: false,
  //   threshold: 0.4,
  // })
  return (
    <>
      <Layout>
        <AnimatePresence>
          <Container>
            <ParaCont>
              <FuturaTitle>Work with me</FuturaTitle>
              <Para>
                If you think I would be a good fit for your project, or you
                would like to discuss an idea, contact me via one of my social
                media accounts, or send me a contact form below.
              </Para>
              <SocialWrap>
                <Social>
                  <a
                    href="https://www.linkedin.com/in/nickungerer/"
                    alt="LinkedIn"
                  >
                    <Lit />
                  </a>
                </Social>
                <Social>
                  <a
                    href="https://twitter.com/nickmustfall"
                    alt="Twitter profile"
                  >
                    <Twitt />
                  </a>
                </Social>
                {/* <Social>GitHub</Social> */}
              </SocialWrap>
            </ParaCont>
          </Container>

          <MiniCont>
            <FuturaTitle>Contact me</FuturaTitle>
          </MiniCont>
          <NetlifyForm />
        </AnimatePresence>
      </Layout>
      <Footer />
    </>
  )
}

const Container = styled.section`
  background: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  color: black;
  height: 90vh;
  margin: 0 auto;
  width: 70%;
  // border: 1px dashed black;
  @media only screen and (max-width: 768px) {
    min-height: 90vh;
    max-height: auto;
    width: 100%;
  }
`

const MiniCont = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding-bottom: 5vh;
`

const FuturaTitle = styled.h2`
  align-self: center;
  color: black;
  font-family: var(--main-font);
  padding: 25px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 4em;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    padding: 0px;
    padding-bottom: 2vh;
    margin-top: 50px;
  }
`

const ParaCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-top: 4vh;
  }
`

const Para = styled.div`
  padding: 15px;
  align-self: center;
  font-size: 1.3em;
  width: 60vw;
  color: black;
  max-height: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1em;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
`

const SocialWrap = styled.div`
  height: 25vh;
  color: black;
  width: 70%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5vh;
  margin-bottom: 4vh;
  align-items: center;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`

const Social = styled.div`
  a {
    color: black;
    padding-top: 1.4vh;
  }
  padding: 5px;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 25%;
  padding-bottom: 10vh;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`

const Twitt = styled(Twit)`
  height: 53px;
  width: 36px;
  margin-left: 15px;
  @media screen and (max-width: 768px) {
    //color: lightblue;
  }
`

const Lit = styled(Li)`
  height: 53px;
  width: 36px;
  margin-left: 15px;
  @media screen and (max-width: 768px) {
    //color: lightblue;
  }
`

const Footer = styled.div`
  height: 10vh;
  background: #f2f2f2;
`
